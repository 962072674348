<template>
  <div id="app" class="app" @scroll="handleScroll" ref="app">
    <Header v-if="name != 'blank'"></Header>
    <router-view></router-view>
    <div class="flex1"></div>
    <Footer v-if="name != 'blank'"></Footer>

    <!-- 审核拒绝 -->
    <div class="layer_bg result" v-if="layer == 1">
      <div class="layer">
        <div class="layer_li_title">Registration Rejected</div>
        <img class="user_no" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/user_no.png" alt="" srcset="">
        <div class="layer_hint">Sorry, your registration was rejuected due to the following reasons :{{ resultMsg }}
        </div>
        <div class="layer_btn pointer" @click="gologin()">Register Again</div>
      </div>
      <img class="layer_close pointer" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/close.png"
        @click="layer = 0">
    </div>
  </div>
</template>

<script>

// (function (w, d, s, l, i) {
//   w[l] = w[l] || []; w[l].push({
//     'gtm.start':
//       new Date().getTime(), event: 'gtm.js'
//   }); var f = d.getElementsByTagName(s)[0],
//     j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
//       'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
// })(window, document, 'script', 'dataLayer', 'GTM-K57VR7X');
// function BoschPrivacyCallback_OnUpdate(a) { a.marketing && dataLayer.push({ event: "bosch_privacy_update_marketing_on" }) }


// window.dataLayer = window.dataLayer || [];
// window.dataLayer.push({
//   "rbaadtid": "2615732c-ee2f-4a35-ab93-ae9e1d5c6951"
// });


// window.onload = function () {
//   dockPrivacySettings.setAttribute('link-url-marketing', 'login_rule_list?type=1')
//   dockPrivacySettings.setAttribute('link-url-policy', 'login_rule_list?type=1')
//   dockPrivacySettings.setAttribute('link-url-imprint', 'http://www.bosch-aa.com.cn/zh_cn/internet/parts/legal_info/imprint.html')
//   return
// }

import Header from "@/components/Header"
import Footer from "@/components/Footer"
import { EventBus } from "./main"
export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      fiexd: false,
      scrollTop: '',
      layer: 0,
      resultMsg: '',
      name: '',
      parameter: '',
      APPTop: false
    }
  },
  watch: {
    $route(to, from, next) {
      var that = this
      // that.$refs.app.scrollIntoView({ behavior: 'smooth' });
      // console.log('from________________', from.name)
      this.client_verifyWhetherAuditIsSuccessful()
      // console.log('0000to.to==', to)
      var nav
      var analytics
      this.name = to.name
      if (to.name == 'Home') {
        nav = 1
        analytics = "home"
      } else if (to.name == 'WhatsOn') {
        nav = 2
        analytics = "whats on"
      } else if (to.name == 'Auto_tech') {
        nav = 3
        analytics = "auto tech"
      } else if (to.name == 'Module') {
        nav = 4
        analytics = "module"
      } else if (to.name == 'MyAccount' || to.name == 'index_Account' || to.name == 'login_index') {
        nav = 5
        if (to.name == 'MyAccount') {
          analytics = "my account"
        }

      } else if (to.name == 'manual_uploading') {
        nav = 6
        analytics = "manual uploading"
      } else if (to.name == 'BSSList') {
        nav = 4
      } else {
        // nav = 0
      }
      if (EventBus) { //埋点
        var countryOld = localStorage.getItem("country") ? JSON.parse(localStorage.getItem("country")) : ''
        var obj = {}
        obj.name = 'wsp_navigation_click',
          obj.params = {
            Typy: 'Users routine operations',
            ID: 'wsp_country_change',
            navigation_name: analytics,
          }
        EventBus.$emit('google-analytics', obj);
        EventBus.$emit('google-analytics_debug', obj);



      }
      if (nav) {
        EventBus.$emit('nav', { nav: nav });
      }

      this.$nextTick(() => {
        if (this.$refs.app) {    // 滚动元素跳转到顶部
          console.log('滚动元素跳转到顶部')
          this.$refs.app.scrollTop = 0;
        }
      })
      that.$refs.app.scrollIntoView({ behavior: 'smooth' });

    }
  },
  mounted() {

    console.log('path===', this.$route.path)

    this.parameter = this.getQueryParams(location.href)
    console.log(' this.parameter===', this.parameter)
    if (localStorage.getItem("nav")) {
      EventBus.$emit('nav', { nav: localStorage.getItem("nav") });
    }
    document.addEventListener('touchstart', function (event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    }, { passive: false });
    if (this.parameter.clientId) {
      localStorage.setItem("form_clientId", this.parameter.clientId);
    }
    if (localStorage.getItem("client_info") && JSON.parse(localStorage.getItem("client_info")).verifyStatus == '01') {
      this.client_verifyWhetherAuditIsSuccessful()
    }
    this.interceptUrl(location.href)


    // 埋点
    // EventBus.$on('google-analytics', (events) => {
    //   console.log('google-analytics_events==', events)



    //   const measurement_id = `G-GKYDL6HJLZ`;
    //   const api_secret = `a2csxM8uRJmLxMlpQWeJRQ`;
    //   var clientInfo = localStorage.getItem("client_info") ? JSON.parse(localStorage.getItem("client_info")) : ''
    //   var country = localStorage.getItem("country") ? JSON.parse(localStorage.getItem("country")) : ''
    //   var currentTimestamp = Date.now();
    //   // 公共属性
    //   var obj = {
    //     url: this.$route.name,
    //     country: country?.countryName || '',
    //     currentTimestamp:currentTimestamp
    //   }

    //   fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurement_id}&api_secret=${api_secret}`, {
    //     method: "POST",
    //     method: "POST",
    //     body: JSON.stringify({
    //       client_id: clientInfo.clientId || '',
    //       user_id: clientInfo.clientUserId || '',
    //       events: [{
    //         name: events.name,
    //         params: { ...events.params, ...obj }
    //       }]
    //     })
    //   });


    // })
     // 测试埋点
     EventBus.$on('google-analytics', (events) => {
      console.log('google-analytics_events==', events)



      const measurement_id = `G-QR6JZGFPES`;
      const api_secret = `9w-vB6W3ShyO3xZi7FqGOQ`;
      var clientInfo = localStorage.getItem("client_info") ? JSON.parse(localStorage.getItem("client_info")) : ''
      var country = localStorage.getItem("country") ? JSON.parse(localStorage.getItem("country")) : ''
      var currentTimestamp = Date.now();
      // 公共属性
      var obj = {
        url: this.$route.name,
        country: country?.countryName || '',
        currentTimestamp:currentTimestamp
      }

      fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurement_id}&api_secret=${api_secret}`, {
        method: "POST",
        method: "POST",
        body: JSON.stringify({
          client_id: clientInfo.clientId || '',
          user_id: clientInfo.clientUserId || '',
          events: [{
            name: events.name,
            params: { ...events.params, ...obj }
          }]
        })
      });


    })
 
    // // 验证埋点
    // EventBus.$on('google-analytics_debug', (events) => {
    //   console.log('debug-google-analytics_events==', events)



    //   const measurement_id = `G-GKYDL6HJLZ`;
    //   const api_secret = `a2csxM8uRJmLxMlpQWeJRQ`;
    //   var clientInfo = localStorage.getItem("client_info") ? JSON.parse(localStorage.getItem("client_info")) : ''
    //   var country = localStorage.getItem("country") ? JSON.parse(localStorage.getItem("country")) : ''
    //   var currentTimestamp = Date.now();
    //   // 公共属性
    //   var obj = {
    //     url: this.$route.name,
    //     country: country?.countryName || '',
    //     currentTimestamp:currentTimestamp
    //   }

    //   fetch(`https://www.google-analytics.com/debug/mp/collect?measurement_id=${measurement_id}&api_secret=${api_secret}`, {
    //     method: "POST",
    //     method: "POST",
    //     body: JSON.stringify({
    //       client_id: clientInfo.clientId || '',
    //       user_id: clientInfo.clientUserId || '',
    //       events: [{
    //         name: events.name,
    //         params: { ...events.params, ...obj }
    //       }]
    //     })
    //   });


    // })
    // 验证埋点
    EventBus.$on('google-analytics_debug', (events) => {
      console.log('debug-google-analytics_events==', events)




      const measurement_id = `G-QR6JZGFPES`;
      const api_secret = `9w-vB6W3ShyO3xZi7FqGOQ`;
      var clientInfo = localStorage.getItem("client_info") ? JSON.parse(localStorage.getItem("client_info")) : ''
      var country = localStorage.getItem("country") ? JSON.parse(localStorage.getItem("country")) : ''
      var currentTimestamp = Date.now();
      // 公共属性
      var obj = {
        url: this.$route.name,
        country: country?.countryName || '',
        currentTimestamp:currentTimestamp
      }

      fetch(`https://www.google-analytics.com/debug/mp/collect?measurement_id=${measurement_id}&api_secret=${api_secret}`, {
        method: "POST",
        method: "POST",
        body: JSON.stringify({
          client_id: clientInfo.clientId || '',
          user_id: clientInfo.clientUserId || '',
          events: [{
            name: events.name,
            params: { ...events.params, ...obj }
          }]
        })
      });


    })


  },
  methods: {
    autologin(url) {

      // 登录失败
      // http://q.ap-portal.boschaftermarket.com/TH/WhatsOn?appCode=EggMall&timestamp=1732863794377&customerId=592274&sign=5d42a303a0a5f219d74a61a06c545de222293905
      // 登录成功
      // http://q.ap-portal.boschaftermarket.com/TH/Home?appCode=EggMall&timestamp=1736386928875&customerId=&sign=1ead3c0b2fc6b3d87b99dc2aa89d750cb2be00b1


      console.log('快速登录')
      var that = this
      that.$flyNo.request({
        method: 'POST',
        url: that.httpUrl.external_auth_auto_login,
        body: {
          appCode: that.parameter.appCode,
          timestamp: that.parameter.timestamp,
          customerId: that.parameter.customerId,
          sign: that.parameter.sign,
        }
      }).then(res => {
        that.parameter = ''
        if (!res) return false

        console.log('--------------——', that.httpUrl.external_auth_auto_login, '_res==', res)
        if (res.resultData.isSuccess == 1) {
          localStorage.setItem("client_info", JSON.stringify(res.resultData.clientInfo))
          localStorage.setItem("clientToken", res.resultData.clientToken)
          var href = url.split('?')[0];
          console.log('href===', href)
          // alert('已切换身份')
          window.location.replace(href);
        }

      })
    },
    getQueryParams(url) {
      // 使用正则表达式提取URL中的查询参数
      const queryString = url.split('?')[1];
      if (!queryString) {
        return {};
      }

      return queryString.split('&').reduce((params, param) => {
        const [key, value] = param.split('=');
        params[decodeURIComponent(key)] = decodeURIComponent(value);
        return params;
      }, {});
    },
    interceptUrl(url) {
      var that = this
      var ind = url.lastIndexOf('/')
      console.log('ind==', ind)
      var str = url.substring(ind - 2, ind)
      console.log('str==', str)
      var country = ''
      if (localStorage.getItem("country")) {
        country = JSON.parse(localStorage.getItem("country"))
        if (country.countryNameAbbreviation == str) {
          console.log('国家重复无需更新')
          if (that.parameter.appCode) {
            if (!that.parameter.customerId) {
              // alert('0改游客')
              this.clearLocalStorage()
              localStorage.removeItem("need_dealer_import")
              var href = url.split('?')[0];
              console.log('href===', href)
              // alert('已切换身份')
              window.location.replace(href);
            } else {
              that.autologin(url)
            }

          }
          return false
        }
      }
      that.$flyNo.request({
        method: 'GET',
        url: that.httpUrl.district_country_list,
        body: {
        }
      }).then(res => {
        if (!res) return false
        console.log('--------------——', that.httpUrl.district_country_list, '_res==', res)
        var countryList = res.resultData.countryList
        countryList.forEach(element => {
          if (element.countryNameAbbreviation == str) {
            localStorage.setItem("country", JSON.stringify(element))
            this.$i18n.locale = 'en';
            localStorage.setItem('localLanguage', 'en')
            console.log('国家已更新')
          }
        });
        if (that.parameter.appCode) {
          if (!that.parameter.customerId) {
            // alert('1改游客')
            this.clearLocalStorage()
            localStorage.removeItem("need_dealer_import")
            var href = url.split('?')[0];
            console.log('href===', href)
            // alert('已切换身份')
            window.location.replace(href);
          } else {
            that.autologin(url)
          }
        } else {
          if (!localStorage.getItem("country")) {
            console.log('回国家页')
            this.$router.push({
              name: "index"
            }, () => { })
          }
        }
      })
    },
    gologin() {
      var that = this
      that.layer = 0
      that.$router.push({
        name: "login_index",
      });
    },
    client_verifyWhetherAuditIsSuccessful() {
      var that = this
      if (!localStorage.getItem("client_info")) return false
      var verifyStatus = JSON.parse(localStorage.getItem("client_info")).verifyStatus
      if (verifyStatus == '01') {
        that.$flyNo.request({
          method: 'GET',
          url: that.httpUrl.client_verifyWhetherAuditIsSuccessful,
          body: {
          }
        }).then(res => {
          if (!res) return false
          // console.log('接口——', that.httpUrl.client_verifyWhetherAuditIsSuccessful, '_res==', res)
          var result = res.resultData.result
          if (result == '03') {
            console.log('审核中~~~~~~~~~~')
            that.clearLocalStorage()
            that.layer = 1
            that.resultMsg = res.resultData.reason
            that.$router.replace({ name: 'Home' }, () => {
              that.clearLocalStorage()
            });
          } else if (result == '02') {
            that.clearLocalStorage()
            that.$message({
              message: "Congratulations! Your application has been approved. Please log in again.",
              customClass: 'custom-message',
              duration: 2000,
              offset: window.screen.height / 2,
              onClose: function () {
                that.gologin()
              }
            });
          }
        })
      }
    },
    handleScroll(event) {
      // 这里处理滚动事件
      // console.log('scrollTop==', event.target.scrollTop); // 输出滚动条的位置
      var scrollTop = event.target.scrollTop
      EventBus.$emit('scrollTop', { scrollTop: scrollTop });

    },
  }

}
</script>
<style lang="scss">
.el-loading-mask {
  background-color: transparent !important;
}

.el-scrollbar .el-scrollbar__bar {
  opacity: 1 !important;
}

.hover2:hover {
  color: #C9E5F6;
}

.el-carousel__arrow {
  line-height: 36px;
}

.el-date-picker {
  width: auto !important;
}

.el-picker-panel__content {
  margin: 15px auto;
}

.el-tag .el-icon-close {
  width: 16px !important;
  height: 16px !important;
}

.user_no {
  margin-top: 26px;
  width: 170px;
}

.btn-next {}

.notify-btn-default {
  background-color: #D9D9D9 !important;
}

.result .layer {
  padding: 22px 38px 27px 38px;
}

.result .layer_li_title {
  font-weight: bold;
  color: #000;
  font-size: 16px;

}

.result .layer_hint {
  color: #000;
  margin-top: 26px;
  font-size: 12px;
}

.el-button--primary {
  background: #007BC0;
}

.el-message-box {
  position: fixed;
  width: 380px;
  left: calc(50% - 190px);
  top: 40vh !important;

}

.el-select__tags {
  justify-content: flex-end;
}

.notify-btn-primary {
  background-color: #007BC0 !important;
  border: 1px solid #007BC0 !important;
}

.picker_border {
  width: 13px;
  height: 1px;
  background-color: #D9D9D9;
  margin: 0 7px;

}

@font-face {
  font-family: 'MyCustomFont';
  src: url('./static/font/BOSCHSANSCHS-REGULAR-V2.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body,
html,
* {
  font-family: 'MyCustomFont', system-ui, sans-serif !important;
  box-sizing: border-box;

}

/* 直接在组件内部覆盖Message的样式 */
.el-upload--picture-card,
.el-upload-list--picture-card .el-upload-list__item {
  width: 101px;
  height: 101px;
}

[class*=" el-icon-"],
[class^=el-icon-] {
  width: auto !important;
}

// span.el-range-separator{
//   height: 100%;
//   line-height: 100%;
//   width: auto;
// }

[class*=" el-icon-"],
[class^=el-icon-] {
  width: 100%;
  height: 100%;
  line-height: inherit;
}

.el-upload--picture-card {
  line-height: 101px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 100%;
  text-align: center;
}

.el-table .el-table__cell {
  text-align: center;

}

.el-table .cell {
  white-space: pre-line;
  word-break: normal;
}

.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: cover !important;
  display: block !important;
  width: 100%;
  height: 100%;
}

.custom-message {
  background-color: rgba(0, 0, 0, .6);
  color: #fff;

  [class*=" el-icon-"],
  [class^=el-icon-] {
    width: initial;
  }
}

.el-textarea.is-disabled .el-textarea__inner,
.el-textarea__inner {
  background: none !important;
  border: none !important;
  color: initial !important;
  text-align: right !important;

}

.el-date-picker__header {
  display: flex;
  align-items: center;
}

.el-date-picker__header--bordered {
  display: flex;
  align-items: center;

  .el-picker-panel__icon-btn {
    margin-top: 0;
  }
}

.el-message {
  min-width: 274px;
  z-index: 9999 !important;
}

.el-message--info .el-message__content {
  color: #fff;
}

::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}

.pointer {
  cursor: pointer
}

.fixed_footer_H {
  height: 65px;
}

.fixed_footer {
  width: 100%;
  position: fixed;
  bottom: 0px;
  height: 84px;
  background: #fff;

  .PC_footer_btn {
    width: 345px;
    height: 44px;
    color: #fff;
    background: #007BC0;
    font-size: 16px;
  }
}

.app {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */

}

* {
  -webkit-overflow-scrolling: touch;
}

.input_R .el-input--suffix .el-input__inner {
  text-align: right !important;
}

.MyAccount .el-input,
.el-input--suffix .el-input__inner {
  text-align: left !important;
}

.hoverColorpointer:hover {
  cursor: pointer !important;
  color: #025180 !important;
}

/* loading.component.css */
.btn_d {
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .btn_L {
    box-sizing: border-box;
    width: calc(50% - 7px);
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #007BC0;
    border: 1px solid #007BC0;
  }

  .btn_R {
    box-sizing: border-box;
    width: calc(50% - 7px);
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #007BC0;
    color: #fff;
  }
}

.red {
  font-weight: normal;
  color: #EB5336;
}

.form_d .el-input__prefix,
.form_d .el-input__suffix {
  height: initial;
  top: calc(50% - 15px);

}

.el-input__prefix {
  left: 12px;
}

.form_d .el-date-editor .el-input__prefix {
  top: calc(50% - 15px);

}

.form_d .seach_input {
  width: 99px;
  flex: initial !important;

  .el-input .el-select__caret {
    transform: initial;
    transition: initial;
    width: inherit;


  }

  .el-input__inner {
    color: transparent;
  }

  .el-input__suffix {
    margin-right: 10px;
    width: 74px;
    height: 28px;
    border-radius: 4px;
    background: #007BC0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 15px);
  }

  .el-icon-arrow-up:before {
    content: "";
    color: #fff;
  }

  .seach_input .form_d .el-select {
    display: flex;
    align-items: center;
  }

}

.loading-overlay {
  background: transparent;
  /* 去掉背景 */
}

.keep-all {
  word-break: break-word;
}

.el-input__icon {
  line-height: inherit !important;
  height: inherit !important;
}

.el-date-editor .el-range-separator {
  width: auto;
}

.el-range-editor.is-disabled input {
  background: none !important;
}

.none_hint {
  width: 100%;
  line-height: 50px;
  text-align: center;
}

.pagination_d {
  display: flex;
  justify-content: center;
  padding: 20px 0;

  .number,
  .btn-next,
  .btn-prev,
  .el-input__inner {
    border: 1px solid #007bc0 !important;
    border-color: #007bc0 !important;
    margin: 0 4px;
    border-radius: 0px;
    min-width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    line-height: 40px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    color: #007bc0;
    font-weight: normal;

  }

  .el-input__inner:focus {
    border-color: #007bc0 !important;
    border: 1px solid #007bc0 !important;
  }


  .el-pager li.active {
    background: #007bc0;
    color: #fff;
  }

}

.el-switch__label.is-active {
  color: #007bc0;
}

.el-input,
.el-input--suffix .el-input__inner,
.input .el-input--suffix .el-input__inner {
  text-align: right;
}

.image-uploader {
  display: flex;
}

.el-upload-list--picture-card {
  margin-right: 10px;
}

.el-icon-loading {
  color: #005432 !important;
}

input:disabled {
  // background: none;
}

.ellipsis1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsis3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.flex1 {
  flex: 1;
}

.relative {
  position: relative;
}

img {
  width: 100%;
  height: auto;
}

.logo .icon-logo {
  width: 186px;
  height: 41px;
}

.ydShow_padding0 {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form_appName {
  flex: 1;
}

.input {
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  border: none !important;
}

.input_L {
  white-space: nowrap;

}


@media (max-width: 1200px) and (min-width: 500px) {
  .container {
    max-width: 700px;
  }
}

@media (max-width: 1200px) and (min-width: 300px) {
  .input {
    height: 100%;
    // border: 1px solid red !important;
  }

  .input_d .input_R {
    max-width: 203px;
  }

  .OTP {
    max-width: 200px !important;
    // border: 1px solid blueviolet!important;
  }




  .form_appName {
    width: 200px;
    margin-right: 0;
    flex: initial;
  }

  .ydShow {
    display: block !important;
  }

  .pcShow {
    display: none !important;
  }

  .fixed_footer_H {
    height: 85px;
  }

  .fixed_footer {
    bottom: 73px;
  }

  .logo .icon-logo {
    width: 118px;
    height: 26px;
  }

}

@media (min-width: 1200px) {
  .pcShow {
    display: block !important;
  }

  .layer {
    max-height: 60vh;
    overflow-y: scroll;
  }

  .ydShow {
    display: none !important;
  }
}

#first {
  width: 100px;
  height: 100px;
  background-color: #fff;
  font-size: 12px;
}

.el-loading-spinner .el-loading-text {
  color: #007BC0 !important;
}

.el-loading-spinner i {
  color: #007BC0 !important;
  stroke-width: 3.5 !important;
}

.pc_banner_title {
  margin-top: 62px;
  font-size: 28px;
  color: #000000;
  margin-bottom: 38px;
  font-weight: bold;
}

.ph_banner_title {
  margin-top: 17px;
  font-size: 18px;
  color: #000000;
  margin-bottom: 17px;
  font-weight: bold;
}

.border_bottom {
  border-bottom: 1px solid #D9D9D9;
}

.check_li {
  white-space: pre-line
}
</style>