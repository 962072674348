<template>
    <div class="page_d">

        <div class="container">
            <div class="container_title">
                {{ $t("message.SelectCountry") }}
            </div>
            <div class="row">
                <div class="country_li col-lg-3 col-sm-12 col-md-12 col-xs-12 col-ts-12 pointer"
                    v-for="item in countryList" :key="item" :label="item.countryName" :value="item"
                    @click="setcountry(item)">
                    <div class="country_li_img">
                        <img :src="item.nationalFlagUrl" alt="img" class="country_img">
                    </div>
                    <span class="country_li_span">{{ item.countryName }}</span>
                </div>
            </div>
            <!-- <div @click="goceshi()">ceshi</div> -->
        </div>
    </div>

</template>

<script>
import { EventBus } from "@/main"
import SvgIcon from "@/components/svgIcon/index.vue"
import "@/components/svgIcon/svg.js" // 批量导入svg文件
export default {

    components: {
        SvgIcon
    },
    name: "index",
    props: {
        msg: String,
    },
    data() {
        return {
            country: '',
            countryList: '',
        }
    },
    created() {

    },
    mounted() {
        console.log('11111')
        this.getdistrict_country_list()


    },

    beforeDestroy() {
        console.log('this.country=========', this.country)
        if (localStorage.getItem("country") && this.country.countryNameAbbreviation) {
            if (this.country.countryNameAbbreviation != JSON.parse(localStorage.getItem("country")).countryNameAbbreviation) {
                this.clearLocalStorage()
            }
        }
    },
    methods: {
        setcountry(item) {
            var that = this
            this.country = item

            if (item.activeStatus != 1) {

                that.$message.closeAll();
                that.$message({
                    message: that.$t("message.WarmingReminderYouCanOnlyAccessMalyssiaNow"),
                    customClass: 'custom-message',
                    duration: 2000,
                    offset: window.screen.height / 2
                });
                return false
            }

            this.$i18n.locale = 'en';
            localStorage.setItem('localLanguage', 'en')
            if (localStorage.getItem("country") && item.countryNameAbbreviation != JSON.parse(localStorage.getItem("country")).countryNameAbbreviation) {
                this.clearLocalStorage()
            }
            if (EventBus) { //埋点
                var countryOld = localStorage.getItem("country") ? JSON.parse(localStorage.getItem("country")) : ''
                var obj = {}
                obj.name = 'Countries switch',
                    obj.params = {
                        Typy: 'Users routine operations',
                        ID: 'wsp_country_change',
                        switcher_type: 'country icon',
                        current_country_name: countryOld.countryName || '',
                        exchange_to_country_name: item.countryName,
                    }
                EventBus.$emit('google-analytics', obj);
            }
            localStorage.setItem("country", JSON.stringify(item))
            var countryNameAbbreviation = JSON.parse(localStorage.getItem("country")).countryNameAbbreviation

            this.$nextTick(() => {

                this.$router.push({ name: 'Home', params: { myParam: countryNameAbbreviation } }, () => {
                    location.reload(); //解决引入规则，首次底部的按钮规则不识别

                });
            })

        },
        goceshi() {
            this.$router.push({ name: 'ceshi' }, () => {
                // location.reload();

            });
        },
        getdistrict_country_list() {
            var that = this
            that.$flyNo.request({
                method: 'GET',
                url: that.httpUrl.district_country_list,
                body: {
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.district_country_list, '_res==', res)
                that.countryList = res.resultData.countryList
                that.$forceUpdate()

            })
        },
    }
}
</script>


<style lang="scss" scoped>
.country_li {
    margin-top: 33px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.country_li_img {
    width: 46px;
    height: auto;
    margin-right: 24px;
}

.country_li_span {
    font-size: 16px;
    color: #000000;
    line-height: 27px;
}

.country_img {
    width: 46px;
    height: auto;
}

.row {
    margin-bottom: 33px;
}
</style>